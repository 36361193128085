<div matDialogTitle>{{ data.title }}</div>
<div matDialogContent>

  <div class="load-projects"
       *ngIf="loadingProjects && !data.gitDatabaseProject">Vänligen vänta medan projekten laddas...
  </div>

  <button
    *ngIf="!loadingProjects && !data.gitDatabaseProject"
    mat-raised-button
    class="load-projects"
    (click)="getProjectsFromGitLab()">Ladda projekt från GitLab
  </button>

  <div>
    <spb-auto-add-project
      *ngIf="deployService.iGitLabProjects$().length > 0"
      [gitDatabaseProjects]="data.gitDatabaseProjects"
      [gitLabProjects]="deployService.iGitLabProjects$()"
      (gitProject)="selectedProject($event)"
    ></spb-auto-add-project>
  </div>

  <form [formGroup]="gitProjectForm" class="form-container">
    <div>
      <mat-form-field>
        <mat-label>Projektnummer</mat-label>
        <label>
          <input formControlName="projectKey" matInput>
        </label>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field>
        <mat-label>Projektnamn</mat-label>
        <label>
          <input formControlName="projectName" matInput>
        </label>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field>
        <mat-label>Beskrivning</mat-label>
        <textarea
          cdkAutosizeMaxRows="5"
          formControlName="projectDescription"
          matInput></textarea>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field>
        <mat-label>Välj fil</mat-label>
        <mat-select [placeholder]="gitLabFiles$.value.length ? 'Välj fil' : 'Laddar filer'"
                    formControlName="packageJson"
                    ngDefaultControl>
          <mat-option *ngFor="let option of gitLabFiles$ | async" [value]="option.path">{{ option.path }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>

  <div matDialogActions>
    <button
      (click)="saveNewProject()"
      color="primary"
      [disabled]="!gitProjectForm.valid"
      mat-raised-button type="button">Spara
    </button>
    <button [mat-dialog-close]="undefined" mat-raised-button type="button">Stäng</button>
  </div>
</div>
