import {Component, Inject, OnInit} from '@angular/core'
import {FormControl, FormGroup, Validators} from '@angular/forms'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {MatSnackBar} from '@angular/material/snack-bar'
import {BehaviorSubject, switchMap} from 'rxjs'
import {filter} from 'rxjs/operators'
import {GitProject, IGitLabProject, TIME_OUT} from '../../../application/data-types'
import {DeployService, GitLabFile} from '../../../services/deploy.service'

export interface NewProjectData {

  /**
   * The title we use to display in the dialog,
   * "new" or "edit" sort of.
   */
  title: string

  /**
   * The project to start with, if any
   */
  gitDatabaseProject?: GitProject

  /**
   * A list of stored project in the database
   */
  gitDatabaseProjects: GitProject[]

}

@Component({
  selector: 'spb-new-project-dialog',
  templateUrl: './new-project-dialog.component.html',
  styleUrls: ['./new-project-dialog.component.scss']
})
export class NewProjectDialogComponent implements OnInit {
  /**
   * accessGranted to gitlab with token
   */
  public accessGranted = true

  /**
   * Access token and group id for collecting git lab projects
   */
  public gitLabAccessToken = ''

  public groupId = '5335894' // Sparbanken Group

  public loadingProjects = false

  public gitLabFiles$: BehaviorSubject<GitLabFile[]> = new BehaviorSubject<GitLabFile[]>([])

  public gitProjectForm: FormGroup = new FormGroup({
    itemId: new FormControl<string>(''),
    projectKey: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required]
    }),
    projectName: new FormControl<string>('', [Validators.required]),
    projectDescription: new FormControl<string>(''),
    packageJson: new FormControl<string>('./package.json', {nonNullable: true, validators: [Validators.required]}),
    timeStamp: new FormControl<number>(0),
    version: new FormControl<number>(0)
  })

  constructor(
    public dialogRef: MatDialogRef<NewProjectDialogComponent>,
    public deployService: DeployService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA)
    public data: NewProjectData
  ) {
  }

  public ngOnInit(): void {
    this.gitLabAccessToken = localStorage.getItem('gitLabAccessToken') as string
    this.gitProjectForm.reset()
    /**
     * If the role already exists and needs to be edited, patch all values to the role form
     */
    if (this.data.gitDatabaseProject) {
      this.gitProjectForm.patchValue(this.data.gitDatabaseProject)
      this.loadFiles()
    }

    this.dialogRef.afterClosed().pipe(
      filter(Boolean)
    )
      .subscribe({
        next: () => {
          this.snackBar.open('Projektet har sparats', 'OK',
            {duration: TIME_OUT, panelClass: ['snackbar-green']})
        }
      })
  }

  /**
   * save a new project
   */
  public saveNewProject() {
    this.dialogRef.close(this.gitProjectForm.value)

    this.deployService.iGitLabProjects$.set(this.deployService.iGitLabProjects$()
      .filter((d: IGitLabProject) => d.id !== Number(this.gitProjectForm.value.projectKey)))

    this.deployService.updateGitProjects(this.gitProjectForm.value).subscribe(
      {
        next: (projects: GitProject[]) => {
          /**
           * update projects that are needed in the select project list of auto-add-project.
           */
          this.data.gitDatabaseProjects = projects
          this.gitProjectForm.reset()
        }
      }
    )
  }

  /**
   * values from the selected project from event emitter is set to the new project form.
   */
  public selectedProject(iGitLabProject: IGitLabProject) {
    /**
     * update the project key, name and description to the add new project form
     */
    this.gitProjectForm.controls.projectKey.setValue(iGitLabProject.id.toString())
    this.gitProjectForm.controls.projectName.setValue(iGitLabProject.name.toString())
    this.gitProjectForm.controls.projectDescription.setValue(iGitLabProject.description.toString())
    this.loadFiles()
  }

  /**
   * get projects from git lab
   */
  public getProjectsFromGitLab() {
    this.deployService.gitProjects.pipe(
      switchMap((gitDatabaseProjects: GitProject[]) => {
        this.data.gitDatabaseProjects = gitDatabaseProjects
        this.loadingProjects = true
        return this.deployService.getProjectsFromGitLab(this.gitLabAccessToken, this.groupId)
      })
    ).subscribe(
      {
        next: (res: IGitLabProject[]) => {
          res
            .sort((a: IGitLabProject, b: IGitLabProject) => a.name.localeCompare(b.name))
          this.deployService.iGitLabProjects$.set(res)
          this.accessGranted = true
          this.loadingProjects = false
        },
        error: () => {
          this.accessGranted = false
          this.loadingProjects = false
          this.snackBar.open('Ditt accessToken är felaktigt', 'OK',
            {duration: TIME_OUT, panelClass: ['snackbar-red']})
        }
      }
    )
  }

  public loadFiles(): void {
    this.gitProjectForm.controls.packageJson.disable({onlySelf: true, emitEvent: false})
    this.deployService.getFilesForProject(this.gitProjectForm.controls.projectKey.value).subscribe({
      next: (files: GitLabFile[]) => {
        this.gitLabFiles$.next(files.filter((file: GitLabFile) => file.path.indexOf('package.json') !== -1))
        this.gitProjectForm.controls.packageJson.enable({onlySelf: true, emitEvent: false})
      }
    })
  }
}
