import {AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core'
import {MatPaginator} from '@angular/material/paginator'
import {MatSort} from '@angular/material/sort'
import {MatTableDataSource} from '@angular/material/table'
import {Subscription} from 'rxjs'
import {MatDialog} from '@angular/material/dialog'
import {MatSnackBar} from '@angular/material/snack-bar'

import {GitProject, TIME_OUT} from '../../../application/data-types'

import {DeleteGitProjectDialogComponent} from '../delete-git-project-dialog/delete-git-project-dialog.component'
import {NewProjectDialogComponent, NewProjectData} from '../new-project-dialog/new-project-dialog.component'
import {DeployService} from '../../../services/deploy.service'
import {GitlabComponent} from '../../gitlab/gitlab.component'
import {filter} from 'rxjs/operators'
import {ConfigService} from '../../../services/config.service'

@Component({
  selector: 'spb-git-projects-list',
  templateUrl: './git-projects-list.component.html',
  styleUrls: ['./git-projects-list.component.scss']
})
export class GitProjectsListComponent implements OnInit, AfterViewInit, OnDestroy {
  /**
   * The boilerplate paginator.
   */
  @ViewChild(MatPaginator) paginator: MatPaginator | null = null

  /**
   * The boilerplate sorter.
   */
  @ViewChild(MatSort, {static: true}) sort: MatSort = new MatSort()

  /**
   * We emit on this when a project is deleted
   */
  @Output() gitProject: EventEmitter<GitProject> = new EventEmitter()

  /**
   * The data in the user table
   */
  public dataSource: MatTableDataSource<GitProject> = new MatTableDataSource<GitProject>()

  /**
   * The columns in the table
   */
  public displayedColumns: string[] = []

  /**
   * projects from database
   */
  public gitDatabaseProjects: GitProject[] = []

  public pleaseWait = ''

  /**
   * Holds the subscription of git projects, when it gets updated.
   */
  private gitProjects$: Subscription = new Subscription()

  private columns: { [key: string]: string[] } = {
    true: ['projectKey', 'projectName', 'projectDescription', 'deployProject', 'deleteItem'],
    false: ['projectKey', 'projectName', 'deleteItem']
  }

  private screen$ = new Subscription()

  constructor(
    private configService: ConfigService,
    public deployService: DeployService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
    /**
     * get all git projects (which is in deploy database table)
     */
    this.gitProjects$ = this.deployService.gitProjects
      .subscribe({
        next: async (gitProjects: GitProject[]) => {
          this.gitDatabaseProjects = gitProjects
          this.gitDatabaseProjects
            .sort((a: GitProject, b: GitProject) => a.projectName.localeCompare(b.projectName))
          this.dataSource.data = this.gitDatabaseProjects
        }
      })

    this.screen$ = this.configService.bigScreen$.subscribe((big: boolean) => {
      this.displayedColumns = this.columns[big + '']
    })
  }

  /**
   * Needs to be after this.dataSource is set, to work
   */
  public ngAfterViewInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }

  /**
   * Make sure not to leak subscriptions
   */
  public ngOnDestroy(): void {
    this.gitProjects$.unsubscribe()
    this.screen$.unsubscribe()
  }

  /**
   * Select row to edit
   */
  public selectRow(row: GitProject) {
    const data: NewProjectData = {
      title: 'Redigera Git Projekt',
      gitDatabaseProject: row,
      gitDatabaseProjects: []
    }

    this.dialog.open(NewProjectDialogComponent, {
      data,
      maxHeight: '90vh'
    })
  }

  /**
   * Delete an item from the list
   */
  public deleteItem(item: GitProject) {
    /**
     * First, send the project to a dialog, to confirm from there that it really should be deleted
     */
    this.dialog.open(DeleteGitProjectDialogComponent, {
      data: item
    }).afterClosed().pipe(filter(Boolean)).subscribe({
      next: (deleted: GitProject) => {
        const projectsLeft = this.gitDatabaseProjects.filter((p: GitProject) => p.projectKey !== deleted.projectKey)
        this.dataSource.data = projectsLeft
        this.deployService.gitProjects.next(projectsLeft)
        this.snackBar.open('Git-projektet har raderats', 'OK',
          {duration: TIME_OUT, panelClass: ['snackbar-green']})
      }
    })
  }

  /**
   * open a dialog to deploy
   */
  public deployProject(item: GitProject) {
    this.dialog.open(GitlabComponent, {
      data: item,
      maxHeight: '90vh'
    })
  }

  /**
   * To apply the filter when search begins
   */
  public applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }
}
