import {Component, OnInit} from '@angular/core'
import {GitProject} from '../../application/data-types'
import {DeployService} from '../../services/deploy.service'
import {MatDialog} from '@angular/material/dialog'
import {NewProjectDialogComponent, NewProjectData} from './new-project-dialog/new-project-dialog.component'

@Component({
  selector: 'spb-git-projects',
  templateUrl: './git-projects.component.html',
  styleUrls: ['./git-projects.component.scss']
})
export class GitProjectsComponent implements OnInit {
  /**
   * git projects from database
   */
  public gitDatabaseProjects: GitProject[] = []


  constructor(private deployService: DeployService,
              private dialog: MatDialog
  ) {
  }

  public ngOnInit(): void {
    this.deployService.getGitProjects().subscribe(
      {
        next: (gitDatabaseProjects: GitProject[]) => {
          this.gitDatabaseProjects = gitDatabaseProjects
        }
      }
    )
  }

  /**
   * Create a new project
   */
  public newProject() {
    const data: NewProjectData = {
      title: 'Nytt projekt',
      gitDatabaseProjects: this.gitDatabaseProjects
    }

    this.dialog.open(NewProjectDialogComponent, {
      data,
      maxHeight: '90vh'
    })
  }
}
